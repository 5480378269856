import {
  configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
  PersistedState, persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  REGISTER,
  PERSIST,
  PURGE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import menusReducer from './menus';
import notifyReducer from './notify';
import staticReducer from './static';
import systemsReducer from './systems';

const persistConfig = {
  key: 'nova-evergreen',
  storage,
  version: 3,
  whitelist: ['systems', 'menus', 'static'],
  blacklist: [],
  migrate: (state: PersistedState) => {
    // eslint-disable-next-line no-underscore-dangle
    if (state?._persist.version !== 3) {
      return Promise.resolve((null as unknown) as PersistedState);
    }
    return Promise.resolve(state);
  },
};

const rootReducer = combineReducers({
  systems: systemsReducer,
  menus: menusReducer,
  notify: notifyReducer,
  static: staticReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
