export const MENU_CODE = {
  HEADER: 'menu-header',
  FOOTER_MAIN: 'menu-footer',
  FOOTER_SUB: 'footer-2',
  RelationShip: 'menu-qhdt',
};

export const PREFIX = {
  NEWS_DETAIL: {
    VI: 'tin-tuc',
    EN: 'news',
  },
  PROJECT_DETAIL: {
    VI: 'du-an',
    EN: 'project',
  },
  NEWS_HASHTAG: {
    VI: 'tin-tuc-truyen-thong',
    EN: 'news-media',
  },
  VIDEO_DETAIL: {
    VI: 'video',
    EN: 'video',
  },
  SHAREHOLDER_RELATIONS_DETAIL: {
    VI: 'tin-tuc-quan-he-co-dong',
    EN: 'relation-news',
  },
  RELATION_SHIP_HASHTAG: {
    VI: 'quan-he-co-dong',
    EN: 'relation',
  },
  SEARCH: {
    VI: 'tim-kiem',
    EN: 'search',
  },
};

export const TEMPLATE_CODE = {
  VIDEO_NEWS: 'VIDEO_NEWS',
  MEDIA: 'MEDIA',
  NEWS_MARKET: 'NEWS_MARKET',
  EVENT_CALENDAR: 'EVENT_CALENDAR',
};

export const LOCAL_STORAGE = {
  LANGUAGE: 'NOVA_EVERGREEN',
};

export default MENU_CODE;
