import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import getMenusService from 'services/menus';
import { MenuItem } from 'services/menus/types';
import { MENU_CODE } from 'utils/constants';
import groupMenus from 'utils/menu';

type InitialState = {
  header: MenuItem[];
  footerMain: MenuItem[];
  footerSub: MenuItem[];
  menuRelationShip: MenuItem[];
};

const initialState: InitialState = {
  header: [],
  footerMain: [],
  footerSub: [],
  menuRelationShip: [],
};

export const getMenusAsync = createAsyncThunk(
  'menu/getMenus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getMenusService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    activeMenuAction($state, action: PayloadAction<{slug:string, status:boolean}>) {
      $state.header = $state.header.map(
        (x) => (x.reference?.slug === action.payload.slug
          ? { ...x, importantActive: action.payload.status }
          : x),
      );
    },
    activeMenuClassAction($state, action: PayloadAction<{class: string, status:boolean}>) {
      $state.header = $state.header.map(
        (x) => (x.cssClass === action.payload.class
          ? { ...x, importantActive: action.payload.status }
          : x),
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(getMenusAsync.fulfilled, ($state, action) => {
      const header = action.payload.find((e) => e.code === MENU_CODE.HEADER)?.items || [];
      const footerMain = action.payload.find((e) => e.code === MENU_CODE.FOOTER_MAIN)?.items || [];
      const footerSub = action.payload.find((e) => e.code === MENU_CODE.FOOTER_SUB)?.items || [];
      const menuRelationShip = action.payload.find(
        (e) => e.code === MENU_CODE.RelationShip,
      )?.items || [];

      $state.header = groupMenus(header) || [];
      $state.footerMain = groupMenus(footerMain) || [];
      $state.footerSub = groupMenus(footerSub) || [];
      $state.menuRelationShip = groupMenus(menuRelationShip) || [];
    });
  },
});

export const { activeMenuAction, activeMenuClassAction } = menusSlice.actions;

export default menusSlice.reducer;
