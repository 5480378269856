import React, { useState } from 'react';

import arrow from 'assets/icons/ic_arrow_to_top.svg';
import useWindowScroll from 'hooks/useWindowScroll';

interface BackToTopProps {
}

const BackToTop: React.FC<BackToTopProps> = () => {
  const [active, setActive] = useState<boolean>(false);
  useWindowScroll(() => {
    if (window.scrollY < 200) {
      setActive(false);
    } else {
      setActive(true);
    }
  });

  return (
    <div
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
      className={`o-backToTop ${active ? 'active' : ''}`}
    >
      <img src={arrow} alt="arrow" width={14} height={17} />
    </div>
  );
};

BackToTop.defaultProps = {
};

export default BackToTop;
