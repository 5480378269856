import { TFunction } from 'react-i18next';

/* eslint-disable no-unused-vars */
export const BASE_URL = process.env.REACT_APP_BASE_URL;
function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export const smoothScrollTo = (
  ref: React.RefObject<HTMLDivElement>,
  duration = 700,
  subMenu?: number,
): void => {
  if (!ref.current) return;
  const heightHeader = window.innerWidth > 992 ? 72 : 59;
  const to = ref.current.offsetTop - heightHeader - (subMenu || 0);
  const element = document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();

  const easeInOutQuad = (
    t: number,
    b: number,
    c: number,
    d: number,
  ): string => {
    let newT = t / (d / 2);
    if (newT < 1) return String((c / 2) * newT * newT + b);
    newT -= 1;
    return String((-c / 2) * (newT * (newT - 2) - 1) + b);
  };

  const animateScroll = (): void => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    element.scrollTop = parseInt(
      easeInOutQuad(currentTime, start, change, duration),
      10,
    );
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  animateScroll();
};

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export const handleScrollCenter = (ref: React.RefObject<HTMLElement | null>,
  classNameEleActive: string) => {
  const eleScroll = ref.current;
  const eleActive = document.querySelector(classNameEleActive);
  if (!eleActive || !eleScroll) return;
  // get width element scroll
  const widthEleScroll = eleScroll.getBoundingClientRect().width;
  // get distance element scroll compared to y window
  const xEleScroll = eleScroll.getBoundingClientRect().x;
  // get width element active
  const widthEleActive = eleActive.getBoundingClientRect().width;
  // get distance element active compared to y window
  const xEleActive = eleActive.getBoundingClientRect().x;
  // get position sroll bar
  const positionScroll = eleScroll.scrollLeft;
  const scrollX = xEleActive - xEleScroll
    + widthEleActive / 2 - widthEleScroll / 2;

  let startTime:number;

  const animateScroll = (timeStamp = 0): void => {
    if (!startTime) {
      startTime = timeStamp;
    }
    const escaped = timeStamp - startTime;

    const scrollPercent = scrollX * Math.min((escaped / 300), 1);
    eleScroll.scrollLeft = positionScroll + scrollPercent;
    if (escaped < 300) {
      requestAnimationFrame(animateScroll);
    }
  };

  animateScroll();
};

export const baseURL = (src?: string): string => `${BASE_URL || ''}${src || ''}`;

export const baseString = (str?: string): string => {
  if (!str) return '';

  return str;
};

export const linkURL = (src?: string): string => {
  if (!BASE_URL || !src) return '';

  return BASE_URL + src;
};

export function getBlockData<T>(
  _code: string,
  listBlock?: BlockDataTypes<T>[],
): T | undefined {
  if (!listBlock) return undefined;
  return listBlock.find((item) => item.code === _code)?.blocks;
}

export function getBannerData(
  _code: string,
  listBlock: BannersDataTypes[],
): BannersData | undefined {
  if (!listBlock) return undefined;
  const findIndex = listBlock.findIndex((item) => item.type === _code);
  if (findIndex < 0) {
    return undefined;
  }
  return listBlock[findIndex].data;
}

export const timingTransaction = (date: string) => {
  const dateFm = new Date(date);
  const month = dateFm.getMonth() + 1 < 10
    ? `0${dateFm.getMonth() + 1}`
    : dateFm.getMonth() + 1;
  const year = dateFm.getFullYear();
  return {
    day:
      dateFm.getDate() < 10
        ? `0${dateFm.getDate()}`
        : dateFm.getDate().toString(),
    monthYear: `${month}/${year.toString()}`,
    month,
  };
};

export const formatDate = (date?: string) => {
  if (!date) return '';

  const dateFm = new Date(date);

  const dateCus = dateFm.getDate() < 10
    ? `0${dateFm.getDate()}`
    : dateFm.getDate().toString();

  const month = dateFm.getMonth() + 1 < 10
    ? `0${dateFm.getMonth() + 1}`
    : dateFm.getMonth() + 1;

  const year = dateFm.getFullYear();

  return `${dateCus}/${month}/${year}`;
};

export const getOgDataPage = (pageData?: PageDataTypes) => ({
  ogDescription: pageData?.ogDescription,
  ogImage: pageData?.ogImage,
  ogTitle: pageData?.ogTitle,
  ogType: pageData?.ogType,
});

export const getTimePastToCurrent = (date?: string, t?: TFunction<'translation', undefined>) => {
  if (!date) return '';
  const dateFormat = new Date(date).getTime();
  const toDay = new Date().getTime();
  const distance = toDay - dateFormat;
  const years = Math.floor(distance / (1000 * 60 * 60 * 24 * 30 * 12));
  const months = Math.floor(distance / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  if (years > 0) return `${years} ${t ? t('page_relationship.year_ago') : ''}`;
  if (months > 0) return `${months} ${t ? t('page_relationship.month_ago') : ''}`;
  if (days > 0) return `${days} ${t ? t('page_relationship.day_ago') : ''}`;
  if (hours > 0) return `${hours} ${t ? t('page_relationship.hour_ago') : ''}`;
  return `${mins > 0 ? mins : 1} ${t ? t('page_relationship.minute_ago') : ''}`;
};

export const youtubeParser = (url?: string) => {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url?.match(regExp);
  // eslint-disable-next-line eqeqeq
  return match && match[7].length == 11 ? match[7] : false;
};

export const youtubeControlIframe = (url: string, isMuted: boolean) => `<iframe src= https://www.youtube.com/embed/${youtubeParser(
  url,
)}?autoplay=1&disablekb=1&enable&controls=1&jsapi=1&loop=1&modestbranding=1&playsinline=1&color=white&mute=${isMuted ? '1' : '0'} frameborder=“0" allowfullscreen />`;
