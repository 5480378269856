import 'App.scss';

import React, { Suspense } from 'react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// import LoadingPage from 'components/atoms/LoadingPage';
import MainLayoutContainer from 'container/Mainlayout';
import useAppInitialize from 'hooks/useAppInitialize';
import useLanguageInitialize from 'hooks/useLanguageIntialize';
import { persistor, store } from 'store';
// import { useAppSelector } from 'store/hooks';
import { PREFIX } from 'utils/constants';

const RelationshipHashtag = React.lazy(() => import('pages/RelationshipHashtag'));
const ShareholderRelationshipDetails = React.lazy(() => import('pages/ShareholderRelationshipDetails'));
const HomeNav = React.lazy(() => import('./navigation/Home'));
const PageNav = React.lazy(() => import('./navigation/Page'));
const DetailMedia = React.lazy(() => import('pages/DetailMedia'));
const MediaHashtag = React.lazy(() => import('pages/MediaHashtag'));
const ProjectDetail = React.lazy(() => import('pages/ProjectDetail'));
const Search = React.lazy(() => import('pages/Search'));
const ErrorContainer = React.lazy(() => import('container/Error'));

const App: React.FC = () => {
  useAppInitialize();
  const { activeLangList } = useLanguageInitialize();

  // if (activeLangList.length <= 0) return null;

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<MainLayoutContainer />}>
          {activeLangList?.map((x, i) => {
            const langPrefix = x.toUpperCase() as LangPrefixTypes;
            return (
              <Route key={`route-${i.toString()}`} path={x === 'vi' ? '' : x}>
                <Route
                  index
                  element={<HomeNav />}
                />
                <Route
                  path=":slug"
                  element={<PageNav />}
                />
                <Route
                  path={PREFIX.SEARCH[langPrefix]}
                  element={<Search />}
                />
                <Route
                  path={`${PREFIX.NEWS_DETAIL[langPrefix]}/:slug`}
                  element={<DetailMedia />}
                />
                <Route
                  path={`${PREFIX.PROJECT_DETAIL[langPrefix]}/:slug`}
                  element={<ProjectDetail />}
                />
                <Route
                  path={`${PREFIX.NEWS_HASHTAG[langPrefix]}/:slug`}
                  element={<MediaHashtag />}
                />
                <Route
                  path={`${PREFIX.SHAREHOLDER_RELATIONS_DETAIL[langPrefix]}/:slug`}
                  element={<ShareholderRelationshipDetails />}
                />
                <Route
                  path={`${PREFIX.RELATION_SHIP_HASHTAG[langPrefix]}/:slug`}
                  element={<RelationshipHashtag />}
                />
              </Route>
            );
          })}
          {activeLangList.length > 0 && (
            <Route
              path="*"
              element={<ErrorContainer statusCode={404} />}
            />
          )}
        </Route>
      </Routes>
    </Suspense>
  );
};

const AppWrapper: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          {/* <GoogleReCaptchaWrapper> */}
          <Router>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </Router>
          {/* </GoogleReCaptchaWrapper> */}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppWrapper;
