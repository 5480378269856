import { PREFIX } from './constants';

import i18n from 'i18n';
import { LanguageKey, LocalesType } from 'services/systems/types';

function getActiveLanguages(locales?: LocalesType) {
  if (locales) {
    return (Object.keys(locales) as Array<LanguageKey>).reduce(
      (prev: Array<LanguageKey>, curr: LanguageKey) => (locales[curr].active
        ? [...prev, curr] : [...prev]),
      [],
    );
  }
  return [];
}

const checkActiveLang = (
  activeLang: LanguageKey,
  listActive?: LocalesType,
): boolean => {
  if (listActive && activeLang) {
    return !!listActive[activeLang].active;
  }
  return false;
};

function getHomeLangURL(lang?: string) {
  if (lang && lang !== 'vi') return `/${lang}`;
  return '/';
}

function getLangURL(lang?: string) {
  if (lang && lang !== 'vi') return `/${lang}/`;
  return '/';
}

const langLabel = (key: string) => {
  switch (key) {
    case 'vi':
      return 'VI';
    case 'en':
      return 'EN';
    default:
      return '';
  }
};

const getStaticSlug = (code: keyof typeof PREFIX) => {
  switch (i18n.language) {
    case 'en':
      return `/en/${PREFIX[code].EN}`;
    default:
      return `/${PREFIX[code].VI}`;
  }
};

const getStaticSlugWithUrl = (code: keyof typeof PREFIX, slug?:string) => {
  if (!slug) return '';
  switch (i18n.language) {
    case 'en':
      return `/en/${PREFIX[code].EN}/${slug}`;
    default:
      return `/${PREFIX[code].VI}/${slug}`;
  }
};

const getHomeLangURLWithI18 = () => {
  if (i18n.language !== 'vi') return `/${i18n.language}`;
  return '/';
};

function getLangWithUrl(slug?: string) {
  if (i18n.language && i18n.language !== 'vi') return `/${i18n.language}/${slug}`;
  return `/${slug}`;
}

export default {
  getActiveLanguages,
  checkActiveLang,
  getHomeLangURL,
  getLangURL,
  langLabel,
  getStaticSlug,
  getStaticSlugWithUrl,
  getHomeLangURLWithI18,
  getLangWithUrl,
};
