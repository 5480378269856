import axiosInstance from 'services/common/instance';

export const getPageService = async <T>(
  slug: string,
): Promise<BasePageDataTypes<T>> => {
  const response = await axiosInstance.get(`pages/${slug}`);
  return response.data.data;
};

export const getStaticHomeService = async <T>(): Promise<
  BasePageDataTypes<T>
> => {
  const response = await axiosInstance.get('pages/static/home-page');
  return response.data.data;
};

export const getStaticAllService = async (): Promise<
  Array<Record<'templateCode' | 'slug' | 'title', string>>
> => {
  const response = await axiosInstance.get('pages/static/all');
  return response.data.data;
};

export const getStaticErrorsService = async (): Promise<DataStaticErrorTypes[]> => {
  const response = await axiosInstance.get('pages/static/errors');
  return response.data.data;
};

export const getPagePreviewService = async (token: string): Promise<any> => {
  const response = await axiosInstance.get(`preview/${token}`);
  return response.data.data;
};
