import React from 'react';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import Modal from 'components/organisms/Modal';

export type NotifyType = 'success' | 'error';

interface NotifyProps {
  isOpen?: boolean;
  handleClose?: () => void;
  handleRedirectHome?: () => void;
  type?: NotifyType;
  title?: string;
  message?: string;
  btnText?: string;
}

const Notify: React.FC<NotifyProps> = ({
  isOpen,
  handleClose,
  type,
  title,
  message,
  btnText,
  handleRedirectHome,
}) => (
  <Modal
    isOpen={!!isOpen}
    modifiers="notify"
    isShowCloseButton={false}
  >
    <div className="o-notify">
      <button className="o-notify_btn-close" type="button" onClick={handleClose}>
        <Icon iconName="closeBlack" iconSize={{ width: 14, height: 14 }} />
      </button>
      <div className="o-notify_content">
        <div className="o-notify_left">
          <Icon iconName={type === 'success' ? 'greenSuccess' : 'redError'} iconSize={{ width: 24, height: 24 }} />
        </div>
        <div className="o-notify_right">
          {title && (
          <div className="o-notify_title">
            <Text
              type="div"
              modifiers={['16x24', '700', 'black085']}
              content={title}
            />
          </div>
          )}
          {message && (
          <div className="o-notify_message">
            <Text type="div" modifiers={['14x22', 'black085', '400']} content={message} />
          </div>
          )}
          <div className="o-notify_btn">
            <Button handleClick={handleRedirectHome} isSquare size="sm">
              {btnText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

Notify.defaultProps = {
  isOpen: undefined,
  handleClose: undefined,
  type: undefined,
  title: undefined,
  message: undefined,
  btnText: undefined,
  handleRedirectHome: undefined,
};

export default Notify;
