import React from 'react';
import Modal from 'react-modal';

import Icon, { IconName } from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

interface Props {
  isOpen: boolean;
  handleClose?: () => void;
  isShowDivider?: boolean;
  isShowCloseButton?: boolean;
  modifiers?: 'personal-intro' | 'notify' | 'utility' | 'jd' | 'infoEvent' | 'infoEventRegister'; // add more modifiers
  iconName?: IconName;
  onAfterOpen?: ()=>void;
}

const CustomModal: React.FC<Props> = ({
  isOpen,
  children,
  modifiers,
  iconName,
  isShowCloseButton,
  isShowDivider,
  handleClose,
  onAfterOpen,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={handleClose}
    onAfterOpen={onAfterOpen}
    closeTimeoutMS={250}
    className={`${mapModifiers('o-modal', modifiers)}`}
    appElement={document.getElementById('root') as HTMLElement}
    ariaHideApp={false}
    htmlOpenClassName="reactmodal-html-open"
    portalClassName={mapModifiers('o-modal_portal', isOpen && 'open', modifiers)}
  >
    <div className="o-modal_main">
      <div className="o-modal_wrapper">
        {isShowCloseButton && (
          <button type="button" className="o-modal_close" onClick={handleClose}>
            <Icon iconName={iconName || 'closeWhite'} />
          </button>
        )}
        <div className="o-modal_body">{children}</div>
        {isShowDivider && (
          <div className="o-modal_divider" />
        )}
      </div>
    </div>
  </Modal>
);

CustomModal.defaultProps = {
  handleClose: undefined,
  isShowDivider: false,
  isShowCloseButton: true,
  modifiers: undefined,
  iconName: 'closeWhite',
  onAfterOpen: undefined,
};

export default CustomModal;
