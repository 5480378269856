import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import NotifyLanguage from 'components/molecules/NotifyLanguage';
import { OptionType } from 'components/molecules/Pulldown';
import { FooterProps } from 'components/organisms/Footer';
import { HeaderProps } from 'components/organisms/Header';
import Notify from 'components/organisms/Notify';
import MainLayout from 'components/templates/MainLayout';
import useGaTracker from 'hooks/useGATracker';
import useGTM from 'hooks/useGTM';
import useLanguage from 'hooks/useLanguage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeNotify } from 'store/notify';
import FN_MENU from 'utils/fnMenu';
import { baseString, baseURL } from 'utils/functions';
import FN_LANGUAGE from 'utils/language';

const MainLayoutContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data, messageNotify } = useAppSelector((state) => state.systems);

  const {
    header: headerMenus,
    footerMain: footerMainMenus,
    footerSub: footerSubMenus,
  } = useAppSelector((state) => state.menus);
  const { lang, handleChangeLang } = useLanguage();
  const notify = useAppSelector((state) => state.notify);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedMembers = (val: OptionType) => {
    window.open(val.id, val.value || '_blank');
    URL.revokeObjectURL(val.id || '');
  };

  const header: HeaderProps = useMemo(() => ({
    logoImage: baseURL(data?.header?.logo),
    headerData: headerMenus,
    socialList: data?.header?.social?.map((e) => ({
      src: baseURL(e.icon),
      slug: baseString(e.link.url),
      target: e.link.target,
    })),
    language: {
      languageActive: lang,
      handleChangeLangue: handleChangeLang,
    },
  }), [data?.header?.logo, data?.header?.social, handleChangeLang, headerMenus, lang]);

  const footer: FooterProps = {
    logo: baseURL(data?.footer?.logo),
    social:
      data?.footer.social.map((e) => ({
        text: e.link.text,
        target: e.link.target,
        url: baseString(e.link.url),
        icon: baseURL(e.icon),
      })) || [],
    officeContent: baseString(data?.footer?.officeContent),
    copyright: baseString(data?.footer?.copyright),
    menu: footerMainMenus,
    option: footerSubMenus?.map((e) => ({
      text: e.title,
      url: FN_MENU.detectLinkWithBaseUrl(e),
      target: e.target,
    })),
    members: {
      title: baseString(data?.members?.title),
      logo: data?.members?.members?.map((m) => ({
        image: baseURL(m.logo),
        url: m?.link?.url,
        target: m?.link?.target,
      })) || [],
      options: data?.members?.membersDropdown?.map((e) => ({
        id: baseString(e.link?.url),
        label: baseString(e.link?.text),
        value: baseString(e.link?.target),
      })) || [],
      onSelected: selectedMembers,
    },
  };

  useGaTracker();
  useGTM();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <MainLayout
      header={header}
      footer={footer}
    >
      <Outlet />
      <Notify
        {...notify}
        handleClose={() => dispatch(closeNotify())}
        btnText={t('button.back_to_home')}
        handleRedirectHome={() => {
          dispatch(closeNotify());
          navigate(FN_LANGUAGE.getHomeLangURLWithI18());
        }}
      />
      <NotifyLanguage
        message={messageNotify.message}
        modifiers={messageNotify.type}
      />
    </MainLayout>
  );
};

export default MainLayoutContainer;
