import React from 'react';
import {
  Col, ColProps, Row, RowProps,
} from 'react-bootstrap';

import mapModifiers from 'utils/functions';

type Type =
  | 'fullScreen'
  | 'noPaddingRight'
  | 'noPaddingLeft'
  | 'fullScreenTabletUp'
  | 'isPaddingHalf'
  | 'isPaddingBig'
  | 'paddingHalfLeftBig'
  | 'paddingHalfRightBig';

interface ContainerProps {
  type?: Type
}

export const CustomRow: React.FC<RowProps> = ({ children, ...props }) => (
  <Row {...props} className={`o-container_row ${props.className}`}>{children}</Row>
);

export const CustomCol: React.FC<ColProps> = ({ children, ...props }) => (
  <Col {...props} className={`o-container_col ${props.className}`}>
    {children}
  </Col>
);

const Container: React.FC<ContainerProps> = ({
  // fullScreen,
  // noPaddingRight,
  // noPaddingLeft,
  // fullScreenTabletUp,
  // isPaddingHalf,
  // isPaddingBig,
  type,
  children,
}) => (
  <div
    className={`container ${mapModifiers(
      'o-container',
      type,
    )}`}
  >
    {children}
  </div>
);

Container.defaultProps = {
  type: undefined,
};

export default Container;
