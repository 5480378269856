import { baseURL } from './functions';

import i18n from 'i18n';
import { MenuItem } from 'services/menus/types';

const getSubMenu = (menus: MenuItem[], slug?: string) => {
  if (!menus?.length || !slug) return [];
  const res = menus.find((m) => {
    if (!m?.subMenu?.length) return undefined;
    if (m?.subMenu.find((e) => e.reference?.slug.includes(slug))) {
      return m;
    }
    return undefined;
  });

  if (!res || !res.subMenu?.length) return [];

  return res.subMenu?.map((e) => ({
    title: e.title,
    slug: renderSlug(e),
    target: e.target,
  }));
};

const renderSlug = (menu: MenuItem): string => {
  if (menu.type === 'custom_link' && menu.link) {
    return menu.link;
  }

  if (menu.reference?.slug) {
    return `${i18n.language === 'vi' ? '/' : `/${i18n.language}/`}${menu.reference.slug}`;
  }

  return '#';
};

const detectLink = (menu: MenuItem): string => {
  if (menu.type === 'custom_link') {
    if (!menu.link && menu.subMenu?.length) {
      return renderSlug(menu.subMenu[0]);
    }
    if (menu.link) {
      return menu.link;
    }
  }

  if (menu.reference?.slug === '/') {
    return i18n.language === 'vi' ? '/' : `/${i18n.language}`;
  }
  if (menu.reference?.slug) {
    return `${i18n.language === 'vi' ? '/' : `/${i18n.language}/`}${menu.reference.slug}`;
  }

  return '#';
};

const recursiveMenuActive = (item: MenuItem, pathName?: string, cb?: () => void) => {
  if (item.importantActive) {
    if (cb) cb();
  }

  if (item.reference?.slug && item.reference?.slug === pathName) {
    if (cb) cb();
  }

  if (item.subMenu) {
    item.subMenu.forEach((x) => {
      recursiveMenuActive(x, pathName, cb);
    });
  }
};

const checkActiveMenu = (item: MenuItem, pathName?: string) => {
  const pathNameLang = i18n.language === 'vi'
    ? pathName?.substring(1)
    : pathName?.substring(4);

  let flag = false;

  recursiveMenuActive(item, pathNameLang, () => {
    flag = true;
  });
  return flag;
};

const renderSlugNormal = (menu: MenuItem) => {
  if (menu.type === 'custom_link' && menu.link) {
    return menu.link;
  }

  if (menu.reference?.slug) {
    return `${i18n.language === 'vi' ? '/' : `/${i18n.language}/`}${menu.reference.slug}`;
  }

  return undefined;
};

const detectLinkWithBaseUrl = (menu: MenuItem): string => {
  if (menu.type === 'custom_link') {
    if (menu.link) {
      return menu.link.includes('http') ? menu.link : baseURL(menu.link);
    }
  }

  if (menu.reference?.slug === '/') {
    return i18n.language === 'vi' ? '/' : `/${i18n.language}`;
  }
  if (menu.reference?.slug) {
    return `${i18n.language === 'vi' ? '/' : `/${i18n.language}/`}${menu.reference.slug}`;
  }

  return '#';
};

export default {
  recursiveMenuActive,
  checkActiveMenu,
  getSubMenu,
  detectLink,
  renderSlugNormal,
  detectLinkWithBaseUrl,
};
