/* eslint-disable consistent-return */
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { useAppSelector } from 'store/hooks';

const useGTM = () => {
  const { data } = useAppSelector((state) => state.systems);

  useEffect(() => {
    const onPageLoad = () => {
      if (data?.gtmId) {
        TagManager.initialize({
          gtmId: data?.gtmId,
        });
      }
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [data]);
};

export default useGTM;
