import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useLanguage from './useLanguage';

import { changeStoreLanguage, initLanguage } from 'i18n';
import { LanguageKey } from 'services/systems/types';
import { useAppSelector } from 'store/hooks';
import FN_LANGUAGE from 'utils/language';

const useLanguageInitialize = () => {
  const location = useLocation();
  const { handleShowModal } = useLanguage();

  const {
    data: dataSystem,
  } = useAppSelector((state) => state.systems);

  const activeLangList = useMemo(
    () => FN_LANGUAGE.getActiveLanguages(dataSystem?.locales),
    [dataSystem?.locales],
  );

  useEffect(() => {
    const checkLanguage = changeStoreLanguage(location.pathname);
    const find = activeLangList.find((x) => x === checkLanguage.language);
    if (checkLanguage.change) {
      if (find) {
        window.location.reload();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (dataSystem?.locales) {
      const languageKey = initLanguage() as LanguageKey;
      const isActive = FN_LANGUAGE.checkActiveLang(languageKey, dataSystem.locales);
      if (!isActive) {
        const { message } = dataSystem.locales[languageKey];
        handleShowModal(
          languageKey,
          message,
          () => {
            window.location.href = window.location.origin;
          },
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSystem?.locales, location.pathname]);

  return {
    activeLangList,
  };
};

export default useLanguageInitialize;
