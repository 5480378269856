import React, {
  useState,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import useDetectHeader from 'hooks/useDetectHeader';
import useDetectHeaderScroll from 'hooks/useDetectHeaderScroll';
import { MenuItem } from 'services/menus/types';
import { LanguageKey } from 'services/systems/types';
import FN_MENU from 'utils/fnMenu';
import mapModifiers from 'utils/functions';
import FN_LANGUAGE from 'utils/language';

interface InputSearchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const InputSearch = React.forwardRef<HTMLInputElement, InputSearchProps>(
  ({ ...props }, ref) => (
    <div className="a-input-search">
      <input
        ref={ref}
        type="text"
        className="a-input-search_input"
        {...props}
      />
    </div>
  ),
);

interface SearchProps {
  onClick?: () => void;
}

const SearchButton: React.FC<SearchProps> = ({ onClick }) => (
  <button className="search_button" type="button" onClick={onClick}>
    <Icon iconSize={{ width: 24, height: 24 }} iconName="search" />
  </button>
);

interface LanguageProps {
  languageActive: LanguageKey;
  handleChange: (value: LanguageKey) => void;
}

const Language: React.FC<LanguageProps> = ({
  languageActive,
  handleChange,
}) => (
  <div className={mapModifiers('language', languageActive === 'vi' ? 'left' : 'right')}>
    <div
      onClick={() => handleChange('vi')}
      className="language_item"
    >
      <span>
        VI
      </span>
    </div>
    <div
      onClick={() => handleChange('en')}
      className="language_item "
    >
      <span>
        EN
      </span>
    </div>
  </div>
);

interface HamburgerProps {
  close?: boolean;
  handleClick?: () => void;
}

const Hamburger: React.FC<HamburgerProps> = ({ close, handleClick }) => (
  <button onClick={handleClick} type="button" className="hamburger">
    <Icon iconName={close ? 'closeWhite' : 'hamburger'} iconSize={{ width: 20, height: 20 }} />
  </button>
);

export interface SocialItemTypes {
  slug: string;
  src: string;
  target?: string;
}
interface SocialProps {
  list?: SocialItemTypes[];
}

const Social: React.FC<SocialProps> = ({ list }) => (
  <ul className="social">
    {list?.map((item, index) => (
      <li className="social_item" key={`social-${index.toString()}`}>
        <Link href={item.slug} target={item.target}>
          <Image alt="social" size="contain" ratio="1x1" imgSrc={item.src} />
        </Link>
      </li>
    ))}
  </ul>
);

interface NavProps {
  headerData?: MenuItem[];
  toggleMenu?: () => void;
  pathName?: string;
  childMenu?: boolean;
}

const Nav: React.FC<NavProps> = ({
  headerData, pathName, childMenu, toggleMenu,
}) => (
  <ul className={`menu ${childMenu ? 'child' : ''}`}>
    {headerData
      && headerData.map((menu, index) => (
        <li className={`menu_item ${FN_MENU.checkActiveMenu(menu, pathName) ? 'active' : ''}`} key={`_nav${String(index)}`}>
          <NavLink
            className="menu_link"
            to={FN_MENU.detectLink(menu)}
            target={menu.target}
            onClick={toggleMenu}
            end
          >
            {menu.title}
          </NavLink>
          {menu.cssClass === 'collapse' && menu.subMenu && (
            <Nav headerData={menu.subMenu} pathName={pathName} toggleMenu={toggleMenu} childMenu />
          )}
        </li>
      ))}
  </ul>
);

export interface HeaderProps {
  logoImage?: string;
  logoImageSecond?: string;
  headerData?: MenuItem[];
  socialList?: SocialItemTypes[];
  language?: {
    languageActive?: LanguageKey;
    handleChangeLangue: (lang: LanguageKey) => void;
  }
}

const Header: React.FC<HeaderProps> = ({
  logoImage,
  // logoImageSecond,
  headerData,
  socialList,
  language,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const refInputSearch = useRef<HTMLInputElement|null>(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const handleToggleSearch = useCallback(() => {
    setIsOpenSearch((prev) => !prev);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsOpenMenu(false);
  }, []);

  useDetectHeaderScroll((status: boolean) => {
    setIsScroll(status);
  });

  useDetectHeader(isOpenMenu, handleCloseMenu);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleCloseMenu();
      setIsOpenSearch(false);
      if (refInputSearch.current?.value) {
        navigate(`${FN_LANGUAGE.getStaticSlug('SEARCH')}?keyword=${refInputSearch.current?.value}`);
      } else {
        navigate(FN_LANGUAGE.getStaticSlug('SEARCH'));
      }
    }
  }, [handleCloseMenu, navigate]);

  const renderUtilities = useCallback(
    (hasSocial?: boolean) => (
      <div className="utilities">
        {hasSocial && (
          <div className="utilities_social">
            <Social list={socialList} />
          </div>
        )}
        <span
          className={`utilities_separate ${
            hasSocial && !socialList?.length ? 'none' : ''
          }`}
        />
        <div className="utilities_language">
          <Language
            languageActive={language?.languageActive || 'vi'}
            handleChange={(lang) => language && language?.handleChangeLangue(lang)}
          />
        </div>
        <div className="utilities_search">
          <SearchButton onClick={handleToggleSearch} />
        </div>
      </div>
    ),
    [handleToggleSearch, socialList, language],
  );

  return (
    <div
      className={mapModifiers(
        'o-header',
        isOpenMenu && 'open',
        isScroll && 'scroll',
      )}
    >
      <Container>
        <div className={`o-header_search ${isOpenSearch ? 'open' : ''}`}>
          <button
            className="button-close"
            type="button"
            onClick={handleToggleSearch}
          >
            <Icon iconSize={{ width: 42, height: 42 }} iconName="closeWhite" />
          </button>
          <div className="o-header_search-content">
            <Text modifiers={['uppercase', 'white', '48x60', 'center', '700']}>
              {t('search.title_search')}
            </Text>
            <div className="o-header_search-input">
              <InputSearch onKeyDown={handleKeyDown} ref={refInputSearch} placeholder={t('search.input_search_placeholder')} />
            </div>
          </div>
        </div>
        <div className="o-header_wrap">
          <div className="o-header_hamburger">
            <Hamburger handleClick={() => setIsOpenMenu(true)} />
          </div>
          <div className="o-header_left">
            <div className="logo">
              <Link href={FN_LANGUAGE.getHomeLangURLWithI18()} target="_self">
                <div className="logo_main">
                  <Image
                    size="cover"
                    alt="logo"
                    ratio="1x1"
                    imgSrc={logoImage || ''}
                  />
                </div>
              </Link>
              {/* <div className="logo_separate" />
              <div className="logo_sub">
                <Image size="cover" alt="logo" ratio="53x33" imgSrc={logoImageSecond || ''} />
              </div> */}
            </div>
          </div>
          <div className="o-header_right">
            <div className="o-header_sub">{renderUtilities(true)}</div>
            <div className="o-header_main">
              <div className="o-header_hamburger-search">
                <Hamburger close handleClick={handleCloseMenu} />
                <SearchButton onClick={handleToggleSearch} />
              </div>
              <Nav
                pathName={pathname}
                headerData={headerData}
                toggleMenu={handleCloseMenu}
              />
              <div className="o-header_main-social">
                <Social list={socialList} />
              </div>
              {isScroll && renderUtilities(false)}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

Hamburger.defaultProps = {
  close: undefined,
  handleClick: undefined,
};

Social.defaultProps = {
  list: undefined,
};

SearchButton.defaultProps = {
  onClick: undefined,
};

Nav.defaultProps = {
  headerData: undefined,
  toggleMenu: undefined,
  pathName: undefined,
  childMenu: undefined,
};

export default Header;
