import useDidMount from 'hooks/useDidMount';
import { useAppDispatch } from 'store/hooks';
import { getMenusAsync } from 'store/menus';
import { getStaticAllAsync } from 'store/static';
import { getSystemsAsync } from 'store/systems';

const useAppInitialize = () => {
  const dispatch = useAppDispatch();

  useDidMount(() => {
    dispatch(getSystemsAsync());
    dispatch(getStaticAllAsync());
    dispatch(getMenusAsync());
  });
};

export default useAppInitialize;
