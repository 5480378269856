import React from 'react';
import { NavLink as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface LinkProps extends Omit<RouterLinkProps, 'to'> {
  href?: string;
  search?: string;
}

const Link: React.FC<LinkProps> = ({
  children,
  href,
  search,
  ...props
}) => {
  if (!href) {
    return (
      <span onClick={props.onClick} className={props.className}>
        {children}
      </span>
    );
  }

  if (href.includes('http') || href.includes('mailto:') || href.includes('tel:')) {
    return (
      <a {...props} href={href}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      {...props}
      to={{
        pathname: href,
        search,
      }}
      aria-label="label"
    >
      {children}
    </RouterLink>
  );
};

Link.defaultProps = {
  search: undefined,
  href: undefined,
};

export default Link;
