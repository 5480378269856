import React from 'react';
import { useLocation } from 'react-router-dom';

import BackToTop from 'components/organisms/BackToTop';
import Footer, { FooterProps } from 'components/organisms/Footer';
import Header, { HeaderProps } from 'components/organisms/Header';
import mapModifiers from 'utils/functions';

interface MainLayoutProps {
  header: HeaderProps;
  footer: FooterProps;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  header,
  footer,
}) => {
  const location = useLocation();
  return (
    <>
      <Header {...header} />
      <main className={mapModifiers('t-mainLayout', location.pathname === '/' && 'home')}>
        {children}
        <BackToTop />
      </main>
      <Footer {...footer} />
    </>
  );
};

MainLayout.defaultProps = {};

export default MainLayout;
