import en from 'translations/en.json';
import vi from 'translations/vi.json';

type BundledResources = Record<string, Record<string, string>>;

type StaticBundled = Record<string, BundledResources | Record<string, string> | string>;

const bundledResources: BundledResources = {
  en: {
    error_500: 'Internal Server Error',
  },
  vi: {
    error_500: 'Lỗi server',
  },
};

export const staticBundledResources: {
  [key: string]: StaticBundled;
} = {
  en: {
    ...en,
  },
  vi: {
    ...vi,
  },
};

export default bundledResources;
