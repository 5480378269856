import { useEffect } from 'react';

const useDetectHeaderScroll = (callback?: (status:boolean) => void) => {
  useEffect(() => {
    const onScroll = () => {
      if (window.innerWidth < 992 || !callback) return;
      if (window.scrollY > 50) {
        callback(true);
      } else {
        callback(false);
      }
    };

    onScroll();

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useDetectHeaderScroll;
