import React from 'react';

import Icon, { IconName, IconSize } from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import mapModifiers from 'utils/functions';

type Variant = 'secondary' | 'outline';
type Sizes = 'sm' | 'xs';

interface ButtonProps {
  size?: Sizes;
  variant?: Variant;
  color?: ColorStyle;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  iconName?: IconName;
  iconSize?: IconSize;
  href?: string;
  target?: string;
  useLink?: boolean;
  isSquare?: boolean;
  fullwidth?: boolean;
  handleClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  isReverse?: boolean;
  iconLoading?: IconName;
  bgLoading?: 'white' | 'green';
}

const Button: React.FC<ButtonProps> = ({
  size,
  variant,
  color,
  type,
  disabled,
  loading,
  handleClick,
  iconName,
  iconSize,
  href,
  target,
  useLink,
  isSquare,
  fullwidth,
  children,
  isReverse,
  iconLoading,
  bgLoading,
}) => {
  if (useLink) {
    return (
      <Link
        className={mapModifiers('a-button', size, variant, color, isSquare && 'square', fullwidth && 'fullwidth', isReverse && 'reverse')}
        href={href || ''}
        target={target}
      >
        <span>
          {children}
        </span>
        {iconName && (
        <span className="a-button_icon">
          <Icon iconName={iconName} iconSize={{ width: 20, height: 20 }} />
        </span>
        )}
      </Link>
    );
  }
  return (
    /* eslint-disable react/button-has-type */
    <button
      className={mapModifiers('a-button', size, variant, color, isSquare && 'square', loading && 'loading', fullwidth && 'fullwidth', isReverse && 'reverse')}
      onClick={handleClick}
      type={type}
      disabled={disabled}
    >
      {loading && (
        <span className={mapModifiers('a-button_loading-icon', bgLoading)}>
          <Icon iconName={iconLoading || 'loadingGreen'} />
        </span>
      )}
      <span>
        {children}
      </span>
      {iconName && (
      <span className="a-button_icon">
        <Icon iconName={iconName} iconSize={iconSize} />
      </span>
      )}
    </button>
  );
};

Button.defaultProps = {
  variant: undefined,
  size: undefined,
  color: undefined,
  type: 'button',
  disabled: false,
  loading: false,
  handleClick: undefined,
  iconName: undefined,
  href: undefined,
  target: undefined,
  useLink: undefined,
  isSquare: undefined,
  fullwidth: undefined,
  isReverse: undefined,
  iconSize: {
    width: 20,
    height: 20,
  },
  iconLoading: undefined,
  bgLoading: 'white',
};

export default Button;
