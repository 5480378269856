import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import getSystemsService from 'services/systems';
import { SystemsData } from 'services/systems/types';

type MessageNotify = {
  message: string;
  type: 'warning' | 'success',
}

type InitialState = {
  messageNotify: MessageNotify;
  data?: SystemsData;
  pageTranslation?: Translation[];
};

const initialState: InitialState = {
  messageNotify: {
    message: '',
    type: 'success',
  },
};

export const getSystemsAsync = createAsyncThunk(
  'systems/getSystems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSystemsService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const systemsSlice = createSlice({
  name: 'systems',
  initialState,
  reducers: {
    setMessageNotify($state, action: PayloadAction<MessageNotify>) {
      $state.messageNotify = action.payload;
    },
    setPageTranslation($state, action: PayloadAction<Translation[] | undefined>) {
      $state.pageTranslation = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemsAsync.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
  },
});

export const {
  setMessageNotify,
  setPageTranslation,
} = systemsSlice.actions;

export default systemsSlice.reducer;
