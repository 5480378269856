import React from 'react';

import Link from 'components/atoms/Link';
import mapModifiers from 'utils/functions';

export const iconList = {
  search: 'search',
  arrowRightWhite: 'arrowRightWhite',
  arrowRightGreen: 'arrowRightGreen',
  mouse: 'mouse',
  arrowNextWhite: 'arrowNextWhite',
  arrowPrevWhite: 'arrowPrevWhite',
  arrowNextBlack: 'arrowNextBlack',
  arrowPrevBlack: 'arrowPrevBlack',
  mail: 'mail',
  phone: 'phone',
  closeWhite: 'closeWhite',
  loadingWhite: 'loadingWhite',
  loadingGreen: 'loadingGreen',
  hamburger: 'hamburger',
  arrowDownGray: 'arrowDownGray',
  location: 'location',
  square: 'square',
  map: 'map',
  download: 'download',
  inputFileBlack: 'inputFileBlack',
  inputFileRed: 'inputFileRed',
  downloadBlack: 'downloadBlack',
  arrowDropDownWhite: 'arrowDropdownWhite',
  arrowRightBlack: 'arrowRightBlack',
  arrowForwardLeftBlack: 'arrowForwardLeftBlack',
  arrowForwardRightBlack: 'arrowForwardRightBlack',
  redError: 'redError',
  greenSuccess: 'greenSuccess',
  closeBlack: 'closeBlack',
  separator: 'separator',
  facebookBlack: 'facebookBlack',
  twitter: 'twitter',
  link: 'link',
  closeGray: 'closeGray',
  linkedin: 'linkedin',
  play: 'play',
  pause: 'pause',
  volume: 'volume',
  volumeMute: 'volumeMute',
  clockGray: 'clockGray',
  clockGreen: 'clockGreen',
  calendarGreen: 'calendarGreen',
  locationGreen: 'locationGreen',
};

// eslint-disable-next-line max-len
export type IconSize = {
  width?: number;
  height?: number;
}

export type IconName = keyof typeof iconList;

interface IconProps {
  iconName: IconName;
  iconSize?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, iconSize }) => (
  <i className={mapModifiers('a-icon', iconName)} style={{ width: iconSize?.width || 24, height: iconSize?.height || 24 }} />
);
interface IconButtonProps extends IconProps {
  href?: string;
  target?: string;
  bgColor?: 'white' | 'transparent';
  buttonSize?: '26' | '30'| '37';
  handleClick?: () => void;
}

export const IconButton:React.FC<IconButtonProps> = ({
  iconName,
  iconSize,
  href,
  target,
  bgColor,
  buttonSize,
  handleClick,
}) => {
  if (href) {
    return (
      <Link className={mapModifiers('a-iconButton', bgColor, buttonSize)} href={href} target={target} aria-label="button">
        <Icon iconName={iconName} iconSize={iconSize} />
      </Link>
    );
  }
  return (
    <button className={mapModifiers('a-iconButton', bgColor, buttonSize)} type="button" onClick={handleClick}>
      <Icon iconName={iconName} iconSize={iconSize} />
    </button>
  );
};

IconButton.defaultProps = {
  handleClick: undefined,
  href: undefined,
  target: undefined,
  iconSize: undefined,
  bgColor: undefined,
  buttonSize: undefined,
};

Icon.defaultProps = {
  iconSize: undefined,
};

export default Icon;
