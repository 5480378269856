import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getStaticAllService } from 'services/navigation';

type InitialState = {
  static?: DataStaticTypes[];
  errors?: DataStaticErrorTypes[]
};

const initialState: InitialState = {
  static: [],
};

export const getStaticAllAsync = createAsyncThunk(
  'static/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getStaticAllService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const systemsSlice = createSlice({
  name: 'systems',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getStaticAllAsync.fulfilled, ($state, action) => {
      $state.static = action.payload;
    });
  },
});

export default systemsSlice.reducer;
