import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';

import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import Container from 'components/organisms/Container';
import { MenuItem } from 'services/menus/types';
import FN_MENU from 'utils/fnMenu';

type SocialItem = {
  icon: string;
  text?: string;
  url: string;
  target?: string;
};

type OptionItem = {
  text: string;
  url?: string;
  target?: string;
  onClick?: () => void;
};

type MembersItem = {
  title: string;
  logo: {
    image: string;
    url: string;
    target?: string;
  }[];
  options: OptionType[];
  onSelected?: (val: OptionType) => void;
}

type LogoProps = Pick<FooterProps, 'logo'>;

type ContentProps = Pick<FooterProps, 'officeContent' | 'social' | 'copyright'>;

type MenuProps = Pick<FooterProps, 'menu'>;

type OptionProps = Pick<FooterProps, 'option' | 'copyright'>;

type MembersProps = Pick<FooterProps, 'members'>;
export interface FooterProps {
  logo: string;
  officeContent: string;
  social: SocialItem[];
  copyright: string;
  menu: MenuItem[];
  option: OptionItem[];
  members: MembersItem;
}

const Logo: React.FC<LogoProps> = ({ logo }) => (
  <div className="o-footer_logo">
    <div className="o-footer_logo_thumbnail">
      <Image imgSrc={logo} ratio="1x1" alt="logo_footer" size="cover" />
    </div>
  </div>
);

const Content: React.FC<ContentProps> = ({
  officeContent,
  social,
  copyright,
}) => (
  <div className="o-footer_content">
    <ul className="o-footer_content_list">
      <li className="o-footer_content_item">
        <Text
          type="div"
          modifiers={['400', '16x24', 'white']}
          content={officeContent}
        />
      </li>
      <li className="o-footer_content_item">
        <div className="o-footer_content_social">
          {social?.map((c, i) => (
            <div
              className="o-footer_content_social_item"
              key={`social${i.toString()}`}
            >
              <Link href={c.url} target={c.target}>
                <Image
                  imgSrc={c.icon}
                  size="cover"
                  ratio="1x1"
                  alt={c.text || ''}
                />
              </Link>
            </div>
          ))}
        </div>
      </li>
      <li className="o-footer_content_item copyRight">
        <Text
          type="div"
          modifiers={['400', 'white', '14x22', 'md']}
          content={copyright}
        />
      </li>
    </ul>
  </div>
);

const Menu: React.FC<MenuProps> = ({ menu }) => (
  <ul className="o-footer_menu">
    {menu?.map((m, i) => (
      <li className="o-footer_menu_item" key={`${m.title}${i.toString()}`}>
        <Link href={FN_MENU.detectLink(m)} target={m.target}>
          <Text modifiers={['white', '16x24', '700']}>
            {m.title}
          </Text>
        </Link>
      </li>
    ))}
  </ul>
);

const Option: React.FC<OptionProps> = ({ option, copyright }) => (
  <div className="o-footer_option">
    <Container>
      <ul className="o-footer_option_list">
        {option?.map((o, i) => (
          <li className="o-footer_option_item" key={`${o.text}${i.toString()}`}>
            {o.onClick ? (
              <div className="pointer-event" onClick={o.onClick}>
                <Text modifiers={['white', '16x24', '700']}>{o.text}</Text>
              </div>
            ) : (
              <Link href={o.url || ''} target={o.target}>
                <Text modifiers={['white', '16x24', '700']}>{o.text}</Text>
              </Link>
            )}
          </li>
        ))}
      </ul>
      <div className="o-footer_copyRightMobile">
        <Text
          type="div"
          modifiers={['400', 'white', '14x22', 'md']}
          content={copyright}
        />
      </div>
    </Container>
  </div>
);

const Members: React.FC<MembersProps> = ({ members }) => {
  const [seleted, setSelected] = useState<OptionType>();
  return (
    <div className="o-footer_members">
      <Text modifiers={['16x24', '400', 'white']}>
        {members?.title}
      </Text>
      <div className="d-flex align-items-center u-mt-16">
        {members?.logo?.map((l, i) => (
          <Link className="o-footer_members_link" href={l.url} target={l.target} key={`logo${i.toString()}`}>
            <div className={`o-footer_members_logo logo${i + 1}`}>
              <Image ratio="78x52" imgSrc={l.image} alt={l.url} size="contain" />
            </div>
          </Link>
        ))}
      </div>
      <div className="u-mt-24">
        <Pulldown
          placeholder="Nova Evergreen"
          options={members?.options}
          handleSelect={(val) => {
            if (members?.onSelected) {
              members?.onSelected(val);
            }
            setSelected(val);
          }}
          value={seleted}
        />
      </div>
    </div>
  );
};

const Footer: React.FC<FooterProps> = ({
  logo,
  officeContent,
  social,
  copyright,
  menu,
  option,
  members,
}) => (
  <footer className="o-footer">
    <LazyLoad height={300} once>
      <div className="o-footer_background" />
      <Container>
        <div className="o-footer_top">
          <Logo logo={logo} />
          <Content
            officeContent={officeContent}
            social={social}
            copyright={copyright}
          />
          <Menu menu={menu} />
          <Members members={members} />
        </div>
      </Container>
      <Option option={option} copyright={copyright} />
    </LazyLoad>
  </footer>
);

export default Footer;
