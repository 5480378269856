import { useCallback, useMemo } from 'react';

import { OptionType } from 'components/molecules/Pulldown';
import { getLocalStorage } from 'services/common/storage';
import { LanguageKey } from 'services/systems/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setMessageNotify } from 'store/systems';
import { LOCAL_STORAGE } from 'utils/constants';
import { baseURL } from 'utils/functions';
import FN_LANGUAGE from 'utils/language';

const useLanguage = () => {
  const dispatch = useAppDispatch();
  const dataSystem = useAppSelector((state) => state.systems);
  const pageTranslation = useAppSelector(
    (state) => state.systems.pageTranslation,
  );
  const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE) || 'vi';

  //* List languages
  const languageData: OptionType[] = useMemo(() => {
    if (dataSystem?.data?.locales) {
      return (Object.keys(dataSystem.data.locales) as Array<LanguageKey>).map(
        (ele, index) => {
          const icon = dataSystem.data && dataSystem.data.locales[ele]?.icon;
          return {
            id: `${ele}-${index}`,
            icon: baseURL(icon),
            value: ele,
            label: FN_LANGUAGE.langLabel(ele),
          };
        },
      );
    }
    return [];
  }, [dataSystem]);

  //* Functions
  const handleChangeLang = (languageKey: LanguageKey) => {
    const isActive = FN_LANGUAGE.checkActiveLang(languageKey, dataSystem?.data?.locales);
    if (isActive) {
      handleLogicChangeLang(languageKey);
    } else {
      const message = dataSystem?.data?.locales
        ? dataSystem.data.locales[languageKey].message
        : '';
      handleShowModal(languageKey, message);
    }
  };

  const checkPageTranslation = (
    langParam: LanguageKey,
    transDataParam?: Translation,
  ) => {
    if (transDataParam?.slug === '/' && langParam !== 'vi') {
      window.location.href = `${window.location.origin}${FN_LANGUAGE.getHomeLangURL(langParam)}`;
      return;
    }
    if (transDataParam?.slug) {
      const pathname = `${FN_LANGUAGE.getLangURL(transDataParam?.locale)}${
        transDataParam?.slug !== '/' ? `${transDataParam?.slug || ''}` : ''
      }`;
      window.location.href = `${window.location.origin}${pathname}`;
    } else {
      window.location.href = `${window.location.origin}${FN_LANGUAGE.getHomeLangURL(langParam)}`;
    }
  };

  const handleLogicChangeLang = async (
    langParam: LanguageKey,
  ) => {
    if (pageTranslation) {
      const transData = pageTranslation.find((ele) => ele.locale === langParam);
      checkPageTranslation(langParam, transData);
    } else {
      window.location.href = `${window.location.origin}${FN_LANGUAGE.getHomeLangURL(langParam)}`;
    }
  };

  const handleShowModal = useCallback(
    (langParam: LanguageKey, message?: string, cb?: ()=>void) => {
      dispatch(setMessageNotify({
        message: message || '',
        type: 'warning',
      }));

      setTimeout(() => {
        dispatch(setMessageNotify({
          message: '',
          type: 'warning',
        }));
        if (cb) {
          cb();
        }
      }, 2000);
    },
    [dispatch],
  );

  //* Memos
  const lang = useMemo(
    () => languageData.find((item) => item.value === language)?.value || 'vi',
    [languageData, language],
  ) as LanguageKey;

  return {
    lang,
    languageData,
    handleChangeLang,
    handleShowModal,
  };
};

export default useLanguage;
